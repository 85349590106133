import axios from 'axios';
import { notice } from '@nextgis/vuetify-notice';
import i18n from '@/i18n';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const pushNotice = (errorText) => {
  notice(errorText, {
    color: 'error',
    timeout: 5000,
    icon: 'error',
  });
};

const addErrorToStore = (errorObj) => {
  store.commit('app/ADD_API_ERROR', errorObj);
};

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!axios.isCancel(error)) {
      const { config, response, message } = error;
      let errorMessage = 'An unknown error occurred';
      if (response) {
        errorMessage =
          response.data && response.data.description
            ? response.data.description[i18n.locale] || response.data.description
            : response.statusText;
      } else if (message) {
        errorMessage = message;
      }

      let errorId;
      if (response) {
        errorId = response.data && response.data.error_id;
      } else if (config) {
        errorId = config.errorId;
      }

      const errorStatus = response && response.status;
      const { saveErrorsToStore, noErrorNotification = false } = config;
      const shouldSaveErrorsToStore = Array.isArray(saveErrorsToStore)
        ? saveErrorsToStore.indexOf(errorId) !== -1
        : saveErrorsToStore;
      if (shouldSaveErrorsToStore) {
        addErrorToStore({
          id: errorId,
          message: errorMessage,
          meta: response && response.data && response.data.meta,
        });
      } else if (!noErrorNotification && (errorStatus === 400 || errorStatus >= 500)) {
        pushNotice(errorMessage);
      }
      return Promise.reject(error);
    }
    const { config } = error;
    console.warn(`${config.url} was cancelled`);
    return Promise.resolve();
  },
);

export default httpClient;
