export const LAST_UPDATED_TODAY = 'today';

export const COMMON_VECTOR_FORMATS = [
  'gpkg',
  'shape',
  'shape-arcgis',
  'geodatabase-arcgis',
  'geojson',
  'mapinfo',
  'mif',
  'kml',
  'postgresql',
  'csv',
];

export const DEFAULT_VECTOR_FORMAT = 'gpkg';

export const PRODUCTS_WORLD = [
  {
    key: 'osm',
    to: { name: 'base', params: { productId: 'base' } },
    textKey: 'product.osm.title',
    icon: 'mdi-earth',
  },
  {
    key: 'dem',
    to: { name: 'dem', params: { productId: 'dem' } },
    textKey: 'product.dem.title',
    icon: 'mdi-image-filter-hdr',
  },
  {
    key: 'msbld',
    to: { name: 'msbld', params: { productId: 'msbld' } },
    textKey: 'product.msbld.title',
    icon: 'mdi-land-plots-circle',
  },
  {
    key: 'msrd',
    to: { name: 'msrd', params: { productId: 'msrd' } },
    textKey: 'product.msrd.title',
    icon: 'mdi-road-variant',
  },
  {
    key: 'overture',
    to: { name: 'simpleDataProduct', params: { productId: 'overture' } },
    textKey: 'product.overture.title',
    icon: 'mdi-earth-box',
  },
  {
    key: 'sat',
    to: { name: 'simpleDataProduct', params: { productId: 'sat' } },
    textKey: 'product.sat.title',
    icon: 'mdi-space-station',
  },
];

export const PRODUCTS = [
  ...PRODUCTS_WORLD,
  {
    key: 'reforma',
    to: { name: 'simpleDataProduct', params: { productId: 'gkh' } },
    textKey: 'product.reforma.title',
    icon: 'mdi-home-city',
  },
  {
    key: 'oopt',
    to: { name: 'simpleDataProduct', params: { productId: 'oopt' } },
    textKey: 'product.oopt.title',
    icon: 'mdi-paw',
  },
  {
    key: 'rnlic',
    to: { name: 'simpleDataProduct', params: { productId: 'rnlic' } },
    textKey: 'product.rnlic.title',
    icon: 'mdi-water',
  },
];
