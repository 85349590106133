import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { getFullFormatList } from '@/utils/formatUtils';
import { getProductImages, getProductionTimeForCachedProducts } from '@/utils/productUtils';
import { COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const MSBLD_IMAGES = [
  { name: 'msbldSatellite', format: 'png' },
  { name: 'msbld', format: 'png' },
];

const MSBLD_FORMAT_LIST = [...COMMON_VECTOR_FORMATS];

const getSettings = (regionCode) =>
  Object.freeze({
    id: 'msbld',
    description: i18n.t('product.msbld.description'),
    lastUpdated: '18.05.2023',
    productionTime: getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
    sources: [
      {
        text: 'Microsoft',
        link: 'https://planetarycomputer.microsoft.com/dataset/ms-buildings',
      },
      {
        text: 'Google',
        link: 'https://sites.research.google/open-buildings/',
      },
    ],
    tags: i18n.t('product.msbld.tags'),
    samplesUrl: `/${i18n.locale}/about/#formats-msbld`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS9EG8OMqxvcPemo-IMWQi_enrZij710YvKPmk3si5lQS_-sA5wCNj4waXefq0zvoouerktx0wrAlNW/pubhtml',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRgaXygAc2KXQp-nGK2t8KcBYqCb6cttxCO6UzUkIdSafVxQmxhUBrGIV3pLjCMi64Ys0_tAwa61ZAy/pubhtml',
      es: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTmmnjYBzi-G2Up5_iK690ZcF0DVCOTM79Z0vtzKSrYL3ykMQ4MPBx1XDxtVymCw_IzmrWgidx67CnW/pubhtml',
      fr: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRViNGrElQJ0zL-a8uzyixLd-N-oBYymSI4x9nMGLyUI9hrJKXjVR2703qGRF5ePucXcDTTOha3zxut/pubhtml',
    },
    demoMapUrl: {
      en: 'https://demo.nextgis.com/resource/7317/display',
      ru: 'https://demo.nextgis.ru/resource/6152/display',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/msbld_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(MSBLD_FORMAT_LIST),
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(MSBLD_IMAGES, 'msbld'),
  });

class MsbldProductService extends BaseProductService {
  getSettings(regionCode) {
    return getSettings(regionCode);
  }
}

export { MsbldProductService, getSettings, MSBLD_IMAGES };
