import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LOCALIZATION } from '@/config';
import enMessages from '@/locales/en.json';
import chatraService from '@/services/chatraService';

Vue.use(VueI18n);

const AVAILABLE_LOCALES = LOCALIZATION.localesShort;
const loadedLanguages = ['en'];
export const browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;

const pluralizeSlavic = (choice, choicesLength) => {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en: enMessages },
  pluralizationRules: { ru: pluralizeSlavic },
});

export const saveLanguage = (language) => {
  localStorage.setItem('language', language);
};

export const getSavedLanguage = () => localStorage.getItem('language');

export const loadLanguageMessages = (language) => {
  return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${language}.json`).then((messages) => {
    i18n.setLocaleMessage(language, messages.default);
    loadedLanguages.push(language);
    return messages.default;
  });
};

export const getDefaultLocale = () => {
  const twoLettersLocale = (getSavedLanguage() || browserLanguage).slice(0, 2);
  return AVAILABLE_LOCALES.includes(twoLettersLocale) ? twoLettersLocale : 'en';
};

export const updateLanguage = async (language) => {
  try {
    if (!loadedLanguages.includes(language)) await loadLanguageMessages(language);
  } catch (error) {
    console.warn(error);
  }
  document.documentElement.setAttribute('lang', language);
  i18n.locale = language;
  saveLanguage(language);
  chatraService.init();
  return language;
};

export default i18n;
