<template>
  <BaseLayout v-bind="$attrs">
    <template v-slot:header-action>
      <region-search-autocomplete
        v-if="hasSearch"
        attach="#header-region-search-autocomplete"
        id="header-region-search-autocomplete"
        class="header-region-search-autocomplete"
        v-model="regionSelectedInSearch"
        @input="goToRegion"
        @update:search-input="onUpdateSearchInput"
        @search="sendEventToCounters"
        :search-query="regionSearchQuery"
        :key="$route && $route.path"
      />
    </template>
    <slot></slot>
  </BaseLayout>
</template>

<script>
import { getCatalogRegionUrl, getCustomRegionUrl, areRoutesHaveSameRegions } from '@/utils/regionRouterUtils';
import BaseLayout from '@ngservices_front/layouts/BaseLayout.vue';
import RegionSearchAutocomplete from '@/components/app/RegionSearchAutocomplete.vue';

export default {
  components: { BaseLayout, RegionSearchAutocomplete },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      regionSelectedInSearch: undefined,
      regionSearchQuery: '',
    };
  },
  methods: {
    onUpdateSearchInput(newQuery) {
      this.regionSearchQuery = newQuery;
    },
    goToRegion() {
      if (this.regionSelectedInSearch) {
        let nextProduct = ['base', 'dem', 'msbld', 'msrd', 'simpleDataProduct'].includes(this.$route.name)
          ? this.$route.name
          : 'base';
        if (nextProduct === 'simpleDataProduct') nextProduct = this.$route.params.productId;
        const nextRoute =
          this.regionSelectedInSearch.source === 'catalog'
            ? getCatalogRegionUrl({ regionCode: this.regionSelectedInSearch.regionData.code, productName: nextProduct })
            : getCustomRegionUrl({
                osmId: this.regionSelectedInSearch.regionData.osmId,
                productName: nextProduct,
              });

        if (nextRoute && !areRoutesHaveSameRegions(nextRoute, this.$route)) this.$router.push(nextRoute);

        this.$nextTick(() => {
          this.regionSelectedInSearch = undefined;
          this.regionSearchQuery = '';
        });
      }
    },
    sendEventToCounters(query) {
      if (window.ym) window.ym(42050389, 'reachGoal', 'app-search', { query });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-region-search-autocomplete {
  width: 200px;
  white-space: normal;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    width: 300px;
  }
}
</style>
