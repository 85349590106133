import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import i18n, { updateLanguage, getDefaultLocale, getSavedLanguage } from '@/i18n';
import { LOCALIZATION, HOST } from '@/config';
import { getCatalogCanonicalUrl, getProductCanonicalUrl, tryScrollToAnchor } from '@/router/routerUtils';
import NgDataBaseLayout from '@/layouts/NgDataBaseLayout.vue';

const Catalog = () => import('@/pages/Catalog.vue');
const About = () => import('@/pages/about/About.vue');
const Faq = () => import('@/pages/faq/FAQ.vue');
const Region = () => import('@/pages/Region.vue');
const OsmProduct = () => import('@/components/product/OsmProduct.vue');
const DemProduct = () => import('@/components/product/DemProduct.vue');
const MsbldProduct = () => import('@/components/product/MsbldProduct.vue');
const MsrdProduct = () => import('@/components/product/MsrdProduct.vue');
const SimpleDataProduct = () => import('@/components/product/SimpleDataProduct.vue');
const RecipeList = () => import('@/pages/RecipeList.vue');
const RecipeEntry = () => import('@/pages/RecipeEntry.vue');
const DataForQgis = () => import('@/pages/DataForQgis.vue');
const ErrorPage = () => import('@ngservices_front/pages/ErrorPage.vue');

Vue.use(VueRouter);
Vue.use(Meta, { refreshOnceOnNavigation: true });

const langMatchRegexp = LOCALIZATION.locales.reduce(
  (acc, locale, index) => (index === 0 ? locale.value : `${acc}|${locale.value}`),
  '',
);
const langParam = `:lang(${langMatchRegexp})`;
const langUrlRegexp = new RegExp(`^/(${langMatchRegexp})(?:/[^?#]*)?$`);

const getLangFromRouteOrDefault = (to) => {
  const match = to.path.match(langUrlRegexp);
  const langFormUrl = match && match[1];
  return langFormUrl || getDefaultLocale();
};

const redirectWithQuery = ({ targetRoute, previousRoute, exclude = [] }) => {
  const previousQueryFiltered = { ...previousRoute.query };
  exclude.forEach((key) => {
    delete previousQueryFiltered[key];
  });

  return {
    ...targetRoute,
    query: { ...previousQueryFiltered, ...targetRoute.query },
  };
};

const redirectToCatalogLevel = (to) => {
  // prоcess old routes with lvl in query
  const lang = getLangFromRouteOrDefault(to);
  if (to.query.lvl === 'countries') {
    return redirectWithQuery({
      targetRoute: {
        name: 'catalog_level',
        params: { lang, level: 'countries' },
      },
      previousRoute: to,
      exclude: ['lvl'],
    });
  }
  return redirectWithQuery({
    targetRoute: {
      name: 'catalog_level',
      params: { lang, level: 'subdivisions' },
    },
    previousRoute: to,
    exclude: ['lvl'],
  });
};

const commonRouteSettings = {
  pathToRegexpOptions: { strict: true },
};

const getSimpleAlternateUrlObjects = (basePartOfRoute, options) => {
  const localesForUrl = (options && options.locales) || LOCALIZATION.localesShort;
  return localesForUrl.map((locale) => ({ lang: locale, url: `${HOST}${locale}${basePartOfRoute}` }));
};

const convertServerProductIdToRouteProductId = (serverProductId) => {
  let productId = serverProductId;
  if (productId === 'osm') productId = 'base';
  if (productId === 'reforma') productId = 'gkh';
  return productId;
};

const productMeta = {
  getTitle: (_, id) => {
    const productId = id !== 'reforma' ? id : 'gkh';
    return i18n.t(`product.${productId}.seotitle`);
  },
  getDescription: (_, id) => {
    const productId = id !== 'reforma' ? id : 'gkh';
    return i18n.t(`product.${productId}.seodescription`);
  },
  getCanonical: (route, id) => {
    const productId = convertServerProductIdToRouteProductId(id);
    return getProductCanonicalUrl({ lang: route.params.lang, regionCode: route.params.regionCode, productId });
  },
  getAlternateUrlObjects: (route, id) => {
    const productId = convertServerProductIdToRouteProductId(id);
    return getSimpleAlternateUrlObjects(`/region/${route.params.regionCode}/${productId}/`);
  },
  layout: {
    component: NgDataBaseLayout,
    props: { fluid: true },
  },
};

const routes = [
  {
    path: '/',
    redirect: (to) => redirectToCatalogLevel(to),
    name: 'index',
    ...commonRouteSettings,
  },
  {
    path: `/${langParam}/`,
    redirect: (to) => redirectToCatalogLevel(to),
    ...commonRouteSettings,
  },
  {
    path: `/${langParam}/catalog/`,
    redirect: (to) => redirectToCatalogLevel(to),
    ...commonRouteSettings,
  },
  {
    path: `/${langParam}/catalog/:level(cities|subdivisions|countries)/`,
    name: 'catalog_level',
    component: Catalog,
    props: true,
    ...commonRouteSettings,
    meta: {
      getCanonical: (route) =>
        getCatalogCanonicalUrl({
          lang: route.params.lang,
          level: route.params.level,
          country: route.query.country,
        }),
      getAlternateUrlObjects: (route) => {
        const basePartOfCatalogRoute = getCatalogCanonicalUrl({
          lang: route.params.lang,
          level: route.params.level,
          country: route.query.country,
        }).replace(`${HOST}${route.params.lang}`, '');
        return getSimpleAlternateUrlObjects(basePartOfCatalogRoute);
      },
      layout: {
        component: NgDataBaseLayout,
        props: {
          fluid: true,
          contentClass: 'grey-bg',
        },
      },
    },
  },
  {
    path: `/${langParam}/about/`,
    name: 'about',
    component: About,
    meta: {
      getTitle: () => `${i18n.t('about.seotitle')} — ${i18n.t('app.seotitle')}`,
      getCanonical: (route) => `${HOST}${route.params.lang}/about/`,
      getAlternateUrlObjects: () => getSimpleAlternateUrlObjects('/about/'),
      layout: {
        component: NgDataBaseLayout,
        props: {
          fluid: true,
        },
      },
    },
    ...commonRouteSettings,
  },
  {
    path: `/${langParam}/faq/`,
    name: 'faq',
    component: Faq,
    meta: {
      getTitle: () => `${i18n.t('faq.seotitle')} — ${i18n.t('app.seotitle')}`,
      getCanonical: (route) => `${HOST}${route.params.lang}/faq/`,
      getAlternateUrlObjects: () => getSimpleAlternateUrlObjects('/faq/'),
      layout: {
        component: NgDataBaseLayout,
        props: {
          fluid: true,
        },
      },
    },
    ...commonRouteSettings,
  },
  {
    path: `/${langParam}/region/:regionCode/`,
    name: 'region',
    component: Region,
    redirect: `/${langParam}/region/:regionCode/base/`,
    props: true,
    ...commonRouteSettings,
    children: [
      {
        path: 'base/',
        name: 'base',
        component: OsmProduct,
        pathToRegexpOptions: { strict: true },
        ...commonRouteSettings,
        meta: productMeta,
      },
      {
        path: 'dem/',
        name: 'dem',
        component: DemProduct,
        ...commonRouteSettings,
        meta: productMeta,
      },
      {
        path: 'msbld/',
        name: 'msbld',
        component: MsbldProduct,
        ...commonRouteSettings,
        meta: productMeta,
      },
      {
        path: 'msrd/',
        name: 'msrd',
        component: MsrdProduct,
        ...commonRouteSettings,
        meta: productMeta,
      },
      {
        path: ':productId(gkh|sat|oopt|rnlic|overture)/',
        name: 'simpleDataProduct',
        component: SimpleDataProduct,
        props: true,
        meta: productMeta,
        ...commonRouteSettings,
      },
    ],
  },
  {
    path: `/${langParam}/howto/`,
    component: {
      render(c) {
        return c('router-view');
      },
    },
    ...commonRouteSettings,
    children: [
      {
        path: `/${langParam}/howto/`,
        name: 'howto',
        component: RecipeList,
        meta: {
          getTitle: () => `${i18n.t('howto.title')} — ${i18n.t('app.seotitle')}`,
          getCanonical: (route) =>
            ['en', 'ru'].includes(route.params.lang) ? `${HOST}${route.params.lang}/howto/` : `${HOST}en/howto/`,
          getAlternateUrlObjects: () => getSimpleAlternateUrlObjects('/howto/', { locales: ['en', 'ru'] }),
          layout: {
            component: NgDataBaseLayout,
            props: { fluid: true },
          },
        },
        ...commonRouteSettings,
      },
      {
        path: ':name/',
        component: RecipeEntry,
        ...commonRouteSettings,
        meta: {
          getCanonical: (route) =>
            ['en', 'ru'].includes(route.params.lang)
              ? `${HOST}${route.params.lang}/howto/${route.params.name}/`
              : `${HOST}en/howto/${route.params.name}/`,
          getAlternateUrlObjects: (route) =>
            getSimpleAlternateUrlObjects(`/howto/${route.params.name}/`, { locales: ['en', 'ru'] }),
          layout: {
            component: NgDataBaseLayout,
            props: { fluid: true, contentCentered: true },
          },
        },
      },
    ],
  },
  {
    path: `/${langParam}/data-for-qgis/`,
    name: 'data_for_qgis',
    component: DataForQgis,
    ...commonRouteSettings,
  },
  {
    path: '*',
    name: '404',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => tryScrollToAnchor(to.hash, 1000, 200));
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const lang = getLangFromRouteOrDefault(to);
  const langFromLocaleStorage = getSavedLanguage();

  const hasTrailingSlash = to.path.endsWith('/');
  if (LOCALIZATION.localesShort.indexOf(lang) > -1 && (lang !== langFromLocaleStorage || lang !== i18n.locale)) {
    updateLanguage(lang);
  }
  if (hasTrailingSlash) {
    next();
  } else {
    const { path, params, hash, query } = to;
    const validRoute = { path: `${path}/`, params, hash, query };
    next(validRoute);
  }
});

export default router;
