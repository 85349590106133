import { isEqual } from 'lodash-es';
import dayjs from 'dayjs';
import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import {
  createSummaryStr,
  createSummaryStrDetailed,
  getProductImages,
  getProductionTimeForCachedProducts,
} from '@/utils/productUtils/';
import { getFormatName, getFullFormatList } from '@/utils/formatUtils';

import 'dayjs/locale/ru';
import { LAST_UPDATED_TODAY, COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const LAYER_NUMBER_FOR_DISCOUNT = 5;
const ALLOWED_DATE_MIN = '2009-05';
const NOW = dayjs().format('DD.MM.YY');

const OSM_FORMAT_LIST_FULL = [
  ...COMMON_VECTOR_FORMATS,
  'pbf-osm',
  'xml-osm',
  // 'pdf',
];
const OSM_FORMAT_LIST_FOR_BIG_AREA = OSM_FORMAT_LIST_FULL.filter(
  (format) => format !== 'geodatabase-arcgis' && format !== 'mapinfo',
);
const BIG_AREA_CODES = ['RU', 'US', 'FR'];

const OSM_IMAGES = [
  { name: 'baseAtd', format: 'png' },
  { name: 'baseHist', format: 'gif' },
  { name: 'baseRoads', format: 'png' },
  { name: 'baseQgis', format: 'png' },
  { name: 'baseMapinfo', format: 'png' },
  { name: 'baseArcgis', format: 'png' },
];

const OSM_NO_LAYERS_FORMATS = ['pbf-osm', 'xml-osm'];

const getSettings = (regionCode) =>
  Object.freeze({
    id: 'osm',
    description: i18n.t('product.osm.description'),
    lastUpdated: LAST_UPDATED_TODAY,
    productionTime: getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
    sources: [
      {
        text: 'OpenStreetMap',
        link: 'https://www.openstreetmap.org/',
      },
    ],
    tags: i18n.t('product.osm.tags'),
    samplesUrl: `/${i18n.locale}/about/#formats-base`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTAv39RP6hZWTaC8aU5vhXN64com_hN1ydLThPR0QvfDI6w0CHouYRnJrq7JSrn6xnKDdhTax23w4qd/pubhtml#',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRIUla6YhWLBPCfKZ6ldWZ_xAO7lc-DtHcS6_MI4knV1RgWgkfm0MOKaQJXHQ1Cnxg8UDl0hXpt4Eqk/pubhtml',
      es: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTmOly1OcD771qSdBp1HCwOZ5NdmTSzCcyEeGs3tNgvMe-EUEvRWF60i_qKKhS7z7EznEPVRoyDMx5F/pubhtml',
      fr: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTUmy8mkLji6CYfend4xT6O8zJ62wIQVGDPIwSfXJ0lml97JxSv_W6byAYkV4PTihJcs_8YCt4ojTJy/pubhtml',
    },
    demoMapUrl: {
      en: 'https://demo.nextgis.com/resource/7003/display?panel=info',
      ru: 'https://demo.nextgis.ru/resource/6630/display?panel=info',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/base_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: BIG_AREA_CODES.includes(regionCode)
            ? getFullFormatList(OSM_FORMAT_LIST_FOR_BIG_AREA)
            : getFullFormatList(OSM_FORMAT_LIST_FULL),
          serverErrors: '',
          errors: [],
        },
        actuality: {
          name: 'actuality',
          widget: 'toggle',
          options: [
            {
              value: 'actual',
              text: `${i18n.t('product.actual')} <span class="d-none d-sm-inline">&nbsp;${i18n.t(
                'product.data',
              )}</span> <small class="text-muted ml-1 d-none d-sm-inline">${NOW}</small>`,
            },
            {
              value: 'historical',
              text: `${i18n.t('product.historical')} <span class="d-none d-sm-inline">&nbsp;${i18n.t(
                'product.data',
              )}</span>`,
            },
          ],
          serverErrors: '',
          errors: [],
          attrs: {
            mandatory: true,
            color: 'primary',
            class: 'actuality-switcher',
          },
          /* help: {
          text: i18n.t('product.actualityHint'),
          attrs: {
            right: true,
            maxWidth: 260,
          },
        }, */
        },
        actualityDate: {
          name: 'actualityDate',
          widget: 'datepicker',
          label: `${i18n.t('product.date')}:`,
          placeholder: i18n.t('product.datePlaceholder'),
          serverErrors: '',
          errors: [],
          formatFunc: (val) => val && dayjs(val).locale(i18n.locale).format('MMMM YYYY'),
          attrs: {
            datepicker: {
              min: ALLOWED_DATE_MIN,
              max: dayjs().subtract(1, 'months').format('YYYY-MM'),
              locale: i18n.locale,
              noTitle: true,
              type: 'month',
            },
            text: {
              class: 'mb-4',
              autofocus: true,
            },
            // menu: {}
          },
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
        actuality: 'actual',
        actualityDate: undefined,
      },
    },
    // images: OSM_IMAGES,
    images: getProductImages(OSM_IMAGES, 'osm'),
  });

class OsmProductService extends BaseProductService {
  getSettings(regionCode) {
    return getSettings(regionCode);
  }

  getSummary({ id, options }) {
    return createSummaryStr({
      id,
      attrs: [getFormatName(options.format)],
      layerNumber: OSM_NO_LAYERS_FORMATS.includes(options.format) ? null : options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { id, options }) {
    return createSummaryStrDetailed({
      regionName,
      id,
      attrs: [[i18n.t('product.format'), getFormatName(options.format)]],
      layerNumber: OSM_NO_LAYERS_FORMATS.includes(options.format) ? null : options.layers.length,
    });
  }

  isOptionsChangedSignificantly(newOptions, oldOptions) {
    if (!oldOptions) return true;

    const { options: newProductOptions, region: newRegion, lang: newLang } = newOptions;
    const { options: oldProductOptions, region: oldRegion, lang: oldLang } = oldOptions;
    const { layers: newLayers, ...newOptionsWithoutLayers } = newProductOptions;
    const { layers: oldLayers, ...oldOptionsWithoutLayers } = oldProductOptions;
    const { geometry: newGeometry } = newRegion;
    const { geometry: oldGeometry } = oldRegion;

    if (newLang !== oldLang) return true;
    if (newGeometry !== oldGeometry) return true;
    if (!isEqual(newOptionsWithoutLayers, oldOptionsWithoutLayers)) return true;

    const isDiscountForLayersChanged =
      (newLayers.length <= LAYER_NUMBER_FOR_DISCOUNT && oldLayers.length > LAYER_NUMBER_FOR_DISCOUNT) ||
      (newLayers.length > LAYER_NUMBER_FOR_DISCOUNT && oldLayers.length <= LAYER_NUMBER_FOR_DISCOUNT);
    if (isDiscountForLayersChanged) return true;

    return false;
  }
}

export { OSM_NO_LAYERS_FORMATS, OSM_IMAGES, OsmProductService };
