import i18n from '@/i18n';
import { CACHED_COUNTRIES } from '@/config';
import { PRODUCTS, PRODUCTS_WORLD } from '@/constants/product/productConstants';
import { addTimestampToUrl } from '@/utils/urlUtils';

const getProductName = (key) => {
  const { textKey } = PRODUCTS.filter((x) => x.key === key).length && PRODUCTS.filter((x) => x.key === key)[0];
  return i18n.t(textKey);
};

const getProductsByKeys = (keys) =>
  PRODUCTS.filter((x) => keys.includes(x.key)).map((x) => ({ ...x, text: i18n.t(x.textKey) }));

const extendProductsByRouteParams = ({ products, regionCode, route }) =>
  products.map((productObject) => {
    return {
      ...productObject,
      to: {
        ...productObject.to,
        params: {
          ...productObject.to.params,
          regionCode,
        },
        query: route.query,
        hash: route.hash,
      },
      value: productObject.key,
    };
  });

const createLayerStr = (number) => `<nobr>${number} ${i18n.tc('product.layer', number)}</nobr>`;

const createSummaryStr = ({ id, attrs, layerNumber }) => {
  const name = getProductName(id);
  const attrStr = `, ${attrs.map((attr) => `<nobr>${attr}</nobr>`).join(', ')}`;
  const layerStr = layerNumber ? `,<br> ${createLayerStr(layerNumber)}` : '';
  return `${name}${attrStr}${layerStr}`;
};

const createSummaryStrDetailed = ({ regionName, id, attrs, layerNumber }) => {
  const layerStr = layerNumber ? `, ${createLayerStr(layerNumber)}` : '';
  const summaryGeneral = `${regionName}, ${getProductName(id)}${layerStr}`;
  const attrStr = attrs.map(([key, value]) => `${key}: <nobr>${value}</nobr>`).join('<br/>');
  return `${summaryGeneral} <br>${attrStr}`;
};

const getProductImages = (imagesSettings, productId) => {
  const lang = i18n.locale === 'ru' ? 'ru' : 'en';
  return imagesSettings.map(({ name, format }) => ({
    src: addTimestampToUrl(`${process.env.VUE_APP_PUBLIC_PATH}img/product/${name}_${lang}.${format}`),
    caption: i18n.t(`product.${productId}.imageCaptions.${name}`),
    caption_detailed: i18n.t(`product.${productId}.imageDetailedCaptions.${name}`),
  }));
};

const getProductListForAboutSamples = (lang) => {
  const productsConfig = lang === 'ru' ? PRODUCTS : PRODUCTS_WORLD;
  return productsConfig.map(({ key, textKey, icon }, index) => {
    let productKey = key;
    if (productKey === 'osm') productKey = 'base';
    if (productKey === 'reforma') productKey = 'gkh';
    return {
      icon,
      text: i18n.t(textKey),
      key: productKey,
      value: index,
    };
  });
};

const getNextProductUrl = ({ lang, regionCode, nextProductQuery }) => {
  const regionCodeLowerCase = regionCode.toLowerCase();
  const productsConfig =
    regionCodeLowerCase === 'ru' ||
    regionCodeLowerCase.includes('ru-') ||
    (regionCodeLowerCase === 'custom' && lang === 'ru')
      ? PRODUCTS
      : PRODUCTS_WORLD;
  const nextProduct = productsConfig.map(({ to }) => to.params.productId).includes(nextProductQuery)
    ? `${nextProductQuery}/`
    : '';
  return `/${lang}/region/${regionCode}/${nextProduct}`;
};

const getProductionTimeForCachedProducts = ({ lang, regionCode }) => {
  if (CACHED_COUNTRIES.includes(regionCode.slice(0, 2))) return 10;
  if (regionCode === 'custom') return lang === 'ru' ? 10 : 30;
  return 40;
};

const getProductionTimeString = (productionTime, options) => {
  const hours = Math.floor(productionTime / 60);
  const minutes = productionTime % 60;
  const minutesMessageKey = options && options.parentCase ? 'app.minutesParentCase' : 'app.minutes';
  const hoursMessageKey = options && options.parentCase ? 'app.hoursParentCase' : 'app.hours';
  if (hours > 0 && minutes > 0)
    return `${hours} ${i18n.tc(hoursMessageKey, hours)} ${minutes} ${i18n.tc(minutesMessageKey, minutes)}`;
  if (hours > 0 && minutes === 0) return `${hours} ${i18n.tc(hoursMessageKey, hours)}`;
  return `${productionTime} ${i18n.tc(minutesMessageKey, productionTime)}`;
};

const getProductPriceObject = ({ lang, price_origin, price_new, discount }) => {
  const currency = lang === 'ru' ? 'RUB' : 'EUR';
  const productPriceObject = {
    originPrice: { amount: price_origin[currency], currency: currency.toLowerCase() },
  };
  if (price_new !== null && price_new !== undefined && typeof price_new[currency] === 'number') {
    productPriceObject.priceWithDiscount = { amount: price_new[currency], currency: currency.toLowerCase() };
  }
  if (discount) productPriceObject.discount = discount;
  return productPriceObject;
};

export {
  getProductName,
  getProductsByKeys,
  extendProductsByRouteParams,
  createSummaryStr,
  createSummaryStrDetailed,
  getProductImages,
  getProductListForAboutSamples,
  getNextProductUrl,
  getProductionTimeForCachedProducts,
  getProductionTimeString,
  getProductPriceObject,
};
