import YoomoneyPaymentGateway from './YoomoneyPaymentGateway';
import StripePaymentGateway from './StripePaymentGateway';
import i18n from '../../i18n';
import { PAYMENT_SUCCESS_QUERY, PAYMENT_FAIL_QUERY } from '../../constants/payment/paymentConstants';

const PAYMENT_ISSUE_COUNTRIES = process.env.VUE_APP_PAYMENT_ISSUE_COUNTRIES;

const getPaymentGateway = (paymentGatewayId) =>
  paymentGatewayId === 'yoomoney' ? new YoomoneyPaymentGateway() : new StripePaymentGateway();

const payForOrder = ({ paymentGatewayId, orderData }) => {
  const paymentGateway = getPaymentGateway(paymentGatewayId);
  if (!paymentGateway) {
    console.error('PaymentGateway is not defined. Inint paymentService');
  }
  paymentGateway.processPayment(orderData);
};

const hasPaymentIssue = (countryCode) => {
  if (i18n.locale !== 'ru') return false;
  // const { from_country } = await userService.getUserVisitInfo();
  return PAYMENT_ISSUE_COUNTRIES.includes(countryCode);
};

const needPaymentWarning = (countryCode) => {
  // const wasPaymentWarningShown = localStorage.getItem('isPaymentWarningShown');
  // if (wasPaymentWarningShown) return false;
  const hasIssue = hasPaymentIssue(countryCode);
  // if (hasIssue) localStorage.setItem('isPaymentWarningShown', true);
  return hasIssue;
};

const getPaymentSuccessUrl = () => `${window.location.href.split('?')[0]}?${PAYMENT_SUCCESS_QUERY}`;

const getPaymentFailUrl = () => `${window.location.href.split('?')[0]}?${PAYMENT_FAIL_QUERY}`;

const isPaymentSucceeded = (queryParams) => Object.keys(queryParams).includes(PAYMENT_SUCCESS_QUERY);

const isPaymentFailed = (queryParams) => Object.keys(queryParams).includes(PAYMENT_FAIL_QUERY);

export default {
  payForOrder,
  getPaymentGateway,
  hasPaymentIssue,
  needPaymentWarning,
  isPaymentSucceeded,
  isPaymentFailed,
  getPaymentSuccessUrl,
  getPaymentFailUrl,
};
