import Vue from 'vue';
import YoomoneyForm from '@/components/payment/YoomoneyForm.vue';
import store from '@/store/';
import i18n from '@/i18n';

const YoumoneyFormClass = Vue.extend(YoomoneyForm);

export default class YoomoneyPaymentGateway {
  constructor() {
    this.name = 'yoomoney';
    this.container = document.createElement('div');
    document.body.appendChild(this.container);
    this.form = null;
  }

  buildForm = () => {
    const form = new YoumoneyFormClass({ store, i18n });
    form.$mount();
    this.container.appendChild(form.$el);
    return form;
  };

  processPayment = (orderData) => {
    if (!this.form) {
      this.form = this.buildForm();
    }
    this.form.orderId = orderData.order_id;
    Vue.nextTick(() => {
      this.form.$el.submit();
    });
  };
}
