import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { getFullFormatList } from '@/utils/formatUtils';
import { getProductImages } from '@/utils/productUtils';
import { COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const OVERTURE_IMAGES = [{ name: 'overture', format: 'png' }];

const getSettings = () =>
  Object.freeze({
    id: 'overture',
    description: i18n.t('product.overture.description'),
    lastUpdated: '12.03.2024',
    productionTime: 5, // in minutes
    sources: [
      {
        text: 'Overture Maps Foundation',
        link: 'https://overturemaps.org/',
      },
    ],
    tags: i18n.t('product.overture.tags'),
    samplesUrl: `/${i18n.locale}/about/#formats-overture`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRJd2RsUimlo1GegSpnJScUdi0kImAJYumnWwbqyb6B2JWMwT9BAyIcIybsqQEBqSllCSNWviGJI9fC/pubhtml?gid=0&single=true',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS2K6m2aTT-CRLZJROrAcsnBAcDPEcWJkxdh9l1dbSxpkSQ3UzusYRixNfCL8v74tXB7DFYjxltRInP/pubhtml?gid=0&single=true',
    },
    demoMapUrl: {
      ru: 'https://demo.nextgis.ru/resource/7150/display',
      en: 'https://demo.nextgis.com/resource/7482/display',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/overture_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(COMMON_VECTOR_FORMATS),
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(OVERTURE_IMAGES, 'overture'),
  });

class OvertureProductService extends BaseProductService {
  getSettings() {
    return getSettings();
  }
}

export { OvertureProductService, getSettings, OVERTURE_IMAGES };
