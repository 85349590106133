<template>
  <NgInput class="ng-autocomplete" v-bind="$attrs">
    <template #default="{ inputAttrs }">
      <v-autocomplete v-bind="inputAttrs" v-on="$listeners">
        <template v-for="(_, slot) of $slots" v-slot:[slot]>
          <slot :name="slot"></slot>
        </template>
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
        >
          <slot :name="name" v-bind="slotData" />
        </template>
      </v-autocomplete>
    </template>
  </NgInput>
</template>

<script>
import NgInput from './NgInput.vue';

export default {
  name: 'NgAutocomplete',
  components: {
    NgInput,
  },
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.ng-autocomplete::v-deep .v-input__control input {
  text-overflow: ellipsis;
}
</style>
