import i18n from '@/i18n';
// eslint-disable-next-line import/no-cycle
import httpClient from '@/api/httpClient';
import { getProductPriceObject } from '@/utils/productUtils';
import { OsmProductService } from './osmProductService';
import { DemProductService } from './demProductService';
import { SatProductService } from './satProductService';
import { GkhProductService } from './gkhProductService';
import { MsbldProductService } from './msbldProductService';
import { MsrdProductService } from './msrdProductService';
import { OvertureProductService } from './overtureProductService';
import { OoptProductService } from './ooptProductService';
import { RnlicProductService } from './rnlicProductService';

const productAbortControllers = new Map();

const services = {
  osm: new OsmProductService(),
  dem: new DemProductService(),
  sat: new SatProductService(),
  reforma: new GkhProductService(),
  msbld: new MsbldProductService(),
  msrd: new MsrdProductService(),
  overture: new OvertureProductService(),
  oopt: new OoptProductService(),
  rnlic: new RnlicProductService(),
};

const getService = (id) => {
  if (services[id]) return services[id];
  throw new Error(`Service for product with id ${id} does not exist`);
};

const lastTwoValidProductData = [];

const getProductPrice = (productOptions) => {
  if (productAbortControllers.has('price')) productAbortControllers.get('price').abort();

  const priceAbortController = new AbortController();
  productAbortControllers.set('price', priceAbortController);
  return httpClient
    .post('api/product_price/', productOptions, {
      signal: priceAbortController.signal,
      saveErrorsToStore: ['empty_area', 'too_big_area', 'polygon_length_greater_360'],
    })
    .then((response) => {
      productAbortControllers.delete('price');
      if (response) {
        const { price_origin, price_new, discount, coupon_status } = response.data.data;
        const productPriceResult = {
          ...getProductPriceObject({ lang: i18n.locale, price_origin, price_new, discount }),
          couponStatus: coupon_status,
        };
        return productPriceResult;
      }
      return null;
    });
};

const validateProductData = (productOptions) => {
  const { layers } = productOptions.options;
  const { geometry } = productOptions.region;
  const errors = [];
  if (layers.length === 0) errors.push(i18n.t('product.errors.specifyLayers'));
  if (productOptions.region.code === 'custom' && !geometry) errors.push(i18n.t('product.errors.specifyArea'));
  if (productOptions.options.format === 'pdf' && !productOptions.options.pdfOptions)
    errors.push(i18n.t('product.errors.specifyPdf'));
  if (productOptions.id === 'dem' && !productOptions.options.contourlines_step)
    errors.push(i18n.t('product.errors.specifyContourLines')); // TODO: show error in UI

  return {
    isValid: !errors.length,
    errors,
  };
};

const getContourLineSteps = (regionCode) => {
  if (productAbortControllers.has('contourLineSteps')) productAbortControllers.get('contourLineSteps').abort();

  const contourLineStepsAbortController = new AbortController();
  productAbortControllers.set('contourLineSteps', contourLineStepsAbortController);

  return httpClient
    .get(`/api/product/dem/countourlines_steps/?region=${regionCode}`, {
      signal: contourLineStepsAbortController.signal,
    })
    .then((resp) => {
      productAbortControllers.delete('contourLineSteps');
      return resp?.data ? resp.data.data : [];
    });
};

const getSummary = (productOptions) => getService(productOptions.id).getSummary(productOptions);

const getSummaryNoHTML = (productOptions) => getSummary(productOptions).replace(/<[^>]*>?/gm, '');

const getSummaryDetailed = (regionName, productOptions) =>
  getService(productOptions.id).getSummaryDetailed(regionName, productOptions);

const getItemCode = (productOptions) => getService(productOptions.id).getItemCode(productOptions);

const updateLastTwoValidProductData = (newProductData) => {
  lastTwoValidProductData.unshift(newProductData);
  if (lastTwoValidProductData.length > 2) lastTwoValidProductData.pop();
};
const resetLastTwoValidProductData = () => {
  lastTwoValidProductData.length = 0;
};

const isOptionsChangedSignificantly = () => {
  return getService(lastTwoValidProductData[0].id).isOptionsChangedSignificantly(
    lastTwoValidProductData[0],
    lastTwoValidProductData[1],
  );
};

const getProductLayers = ({ id, regionCode }) => {
  if (productAbortControllers.has('layers')) productAbortControllers.get('layers').abort();

  const layerAbortController = new AbortController();
  productAbortControllers.set('layers', layerAbortController);

  return httpClient
    .get(`/api/product/${id}/layers/?region=${regionCode}&lang=${i18n.locale}`, {
      noErrorNotification: true,
      signal: layerAbortController.signal,
    })
    .then((response) => {
      productAbortControllers.delete('layers');
      const layersData = response.data.data
        .filter((layer) => layer.featureCount === undefined || layer.featureCount > 0)
        .map((layer) => ({ ...layer }));
      return layersData;
    });
};

const cancelAllProductRequests = () => {
  productAbortControllers.forEach((controller) => controller.abort());
  productAbortControllers.clear();
};

export default {
  getService,
  getProductPrice,
  validateProductData,
  getSummary,
  getSummaryNoHTML,
  getSummaryDetailed,
  getItemCode,
  getProductLayers,
  getContourLineSteps,
  isOptionsChangedSignificantly,
  updateLastTwoValidProductData,
  resetLastTwoValidProductData,
  cancelAllProductRequests,
};
