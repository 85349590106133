import { loadStripe } from '@stripe/stripe-js/pure';

export default class StripePaymentGateway {
  constructor() {
    this.name = 'stripe';
  }

  processPayment = async (orderData) => {
    const stripe = await loadStripe(orderData.payment.stripe.api_key);
    const redirectData = { sessionId: orderData.payment.stripe.session_id };
    stripe.redirectToCheckout(redirectData);
  }
}
