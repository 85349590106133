import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { getFullFormatList } from '@/utils/formatUtils';
import { getProductImages, getProductionTimeForCachedProducts } from '@/utils/productUtils';
import { COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const GKH_IMAGES = [{ name: 'gkh', format: 'png' }];

const getSettings = (regionCode) =>
  Object.freeze({
    id: 'reforma',
    description: i18n.t('product.reforma.description'),
    lastUpdated: '28.05.2024',
    productionTime: getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
    sources: [
      {
        text: i18n.t('product.gkh.reforma'),
        link: 'https://xn--p1aee.xn--p1ai/',
      },
    ],
    tags: i18n.t('product.gkh.tags'),
    samplesUrl: '/ru/about/#formats-gkh',
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS32AGvxMh3_Yxuq_duKTIzQFfrJ88iEBciyn8ruhgGxAmyj2bcksH6JfoOs5p13YfZhPXAEqzRa783/pubhtml',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS32AGvxMh3_Yxuq_duKTIzQFfrJ88iEBciyn8ruhgGxAmyj2bcksH6JfoOs5p13YfZhPXAEqzRa783/pubhtml',
    },
    demoMapUrl: {
      en: 'https://demo.nextgis.ru/resource/5467/display?panel=info',
      ru: 'https://demo.nextgis.ru/resource/5467/display?panel=info',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/gkh_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(COMMON_VECTOR_FORMATS),
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(GKH_IMAGES, 'gkh'),
  });

class GkhProductService extends BaseProductService {
  getSettings(regionCode) {
    return getSettings(regionCode);
  }
}

export { GkhProductService, getSettings, GKH_IMAGES };
