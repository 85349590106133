import { cloneDeep, debounce } from 'lodash-es';
import i18n from '@/i18n';
// eslint-disable-next-line import/no-cycle
import productService from '@/services/product/productService';

const debouncedPriceRequest = debounce(async ({ getters, commit }) => {
  const { productDataFormatted } = getters;
  if (productDataFormatted) {
    const productPrice = await productService.getProductPrice({
      ...productDataFormatted,
    });
    commit('SET_PRICE', productPrice?.originPrice);
    commit('SET_IS_PRICE_LOADING', false);
  }
}, 500);

const product = {
  namespaced: true,
  state: () => ({
    id: null,
    productOptions: null,
    defaultLayers: [], // initial value of layers
    layers: [], // layers selected by user
    price: null,
    isPriceLoading: false,
    url: undefined,
  }),
  getters: {
    productDataFormatted(state, getters, rootState) {
      const { currentRegion, currentRegionCode: code, currentRegionGeometry } = rootState.regions;
      if (state.productOptions && currentRegion && code) {
        const options = {
          ...state.productOptions,
          ...state.productOptions.formModel,
          layers: state.layers,
        };
        delete options.formModel;

        const { country, name } = currentRegion;
        const wkt = currentRegionGeometry && currentRegionGeometry.wkt;
        const region = { code, geometry: wkt, country, name };

        return {
          id: state.id,
          lang: i18n.locale,
          region,
          options,
          url: state.url,
        };
      }
      return null;
    },
  },
  mutations: {
    SET_PRODUCT_ID(state, id) {
      state.id = id;
    },
    SET_PRODUCT_OPTIONS(state, productOptions) {
      state.productOptions = cloneDeep(productOptions);
    },
    SET_PRODUCT_LAYERS(state, layers) {
      state.layers = layers;
    },
    SET_DEFAULT_LAYERS(state, layers) {
      state.defaultLayers = layers;
    },
    SET_PRICE(state, price) {
      state.price = price;
    },
    SET_IS_PRICE_LOADING(state, isPriceLoading) {
      state.isPriceLoading = isPriceLoading;
    },
    SET_PRODUCT_URL(state, url) {
      state.url = url;
    },
  },
  actions: {
    async loadPrice({ commit, dispatch }) {
      commit('SET_IS_PRICE_LOADING', true);
      commit('SET_PRICE', null);
      dispatch('cancelDebouncedPriceRequest');
      dispatch('sendPriceRequest');
    },
    sendPriceRequest: debouncedPriceRequest,
    cancelDebouncedPriceRequest: debouncedPriceRequest.cancel,
  },
};

export default product;
