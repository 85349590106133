const map = {
  namespaced: true,
  state: () => ({
    plugins: [],
    geocoderValue: null,
    boundaryFileValue: null,
    isRectangleDrawingMode: false,
  }),
  getters: {
    isPluginAdded: (state) => (id) => !!state.plugins.filter((x) => x.id === id).length,
    drawingMode: (state) => {
      if (state.geocoderValue) return 'search';
      if (state.boundaryFileValue) return 'file';
      return state.isRectangleDrawingMode ? 'rectangle' : 'polygon';
    },
  },
  mutations: {
    // SET_MAP_GEOMETRY(state, geometry) {
    //   state.geometry = geometry;
    // },
    SET_MAP_PLUGINS(state, plugin) {
      state.plugins = plugin;
    },
    SET_GEOCODER_VALUE(state, geocoderValue) {
      state.geocoderValue = geocoderValue;
    },
    SET_BOUNDARY_FILE_VALUE(state, boundaryFileValue) {
      state.boundaryFileValue = boundaryFileValue;
    },
    SET_IS_RECTANGLE_DRAWING_MODE(state, isRectangle) {
      state.isRectangleDrawingMode = isRectangle;
    },
  },
  actions: {
    addMapPlugin({ state, commit, getters }, plugin) {
      if (!getters.isPluginAdded(plugin.id)) {
        commit('SET_MAP_PLUGINS', [...state.plugins, plugin]);
      }
    },
    removeMapPlugin({ state, commit, getters }, pluginId) {
      if (getters.isPluginAdded(pluginId)) {
        commit(
          'SET_MAP_PLUGINS',
          state.plugins.filter((x) => x.id !== pluginId),
        );
      }
    },
    updateMapPlugin({ state, commit }, plugin) {
      const updatedPlugins = state.plugins.map((x) => (x.id === plugin.id ? plugin : x));
      commit('SET_MAP_PLUGINS', updatedPlugins);
    },
  },
};

export default map;
