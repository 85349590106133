import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { getFullFormatList } from '@/utils/formatUtils';
import { getProductImages, getProductionTimeForCachedProducts } from '@/utils/productUtils';
import { COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const MSRD_IMAGES = [
  { name: 'msrdSat', format: 'png' },
  { name: 'msrd', format: 'png' },
];

const getSettings = (regionCode) =>
  Object.freeze({
    id: 'msrd',
    description: i18n.t('product.msrd.description'),
    lastUpdated: '27.03.2023',
    productionTime: getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
    sources: [
      {
        text: 'Microsoft',
        link: 'https://github.com/microsoft/RoadDetections',
      },
    ],
    tags: i18n.t('product.msrd.tags'),
    samplesUrl: `/${i18n.locale}/about/#formats-msrd`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSx74nB_TPHRYNZTBFSRK5eEi_oxEBFCShT30VRYmk_sisD4qVcwRb-5F_IWiP2x-0pRGFWH8piHnWC/pubhtml',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT6LtVjaJy8GAOwBGFGqjL5NX0meddQbwG0qtiZuqBPMmDsjLAxYWM4ARzFDegb2UG2vhlHaLefOP2s/pubhtml',
    },
    demoMapUrl: {
      en: 'https://demo.nextgis.com/resource/7251/display',
      ru: 'https://demo.nextgis.ru/resource/7076/display',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/msrd_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(COMMON_VECTOR_FORMATS),
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(MSRD_IMAGES, 'msrd'),
  });

class MsrdProductService extends BaseProductService {
  getSettings(regionCode) {
    return getSettings(regionCode);
  }
}

export { MsrdProductService, getSettings, MSRD_IMAGES };
