import { memoize } from 'lodash-es';
import * as regionApi from '@/api/regionApi';
import * as nominatimApi from '@/api/nominatimApi';
import { convertRegionDataToSearchResult, convertNominatimSearchItemToSearchResult } from '@/utils/regionSearchUtils';

const NOMINATIM_RESULTS_LIMIT = 2;

const searchRegionsByQuery = (query, lang, options) =>
  Promise.all([
    regionApi.getRegionsByQuery({ query, lang, count: 5, options }),
    nominatimApi.getNominatinRegionsByQuery({ query, options }),
  ]).then(([regionSearchResponse, nominatimSearchResponse]) => {
    const regionSearchResults = regionSearchResponse
      ? regionSearchResponse.map((region) => convertRegionDataToSearchResult(region))
      : [];
    const nominatimSearchResults =
      nominatimSearchResponse && nominatimSearchResponse.features
        ? nominatimSearchResponse.features.reduce((acc, nominatimFeature) => {
            // only features with osm_type=relation are added
            return (nominatimFeature.properties.osm_type === 'relation' ||
              nominatimFeature.properties.osm_type === 'way') &&
              acc.length < NOMINATIM_RESULTS_LIMIT
              ? [...acc, convertNominatimSearchItemToSearchResult(nominatimFeature.properties)]
              : acc;
          }, [])
        : [];
    return [...regionSearchResults, ...nominatimSearchResults];
  });

const memoizedSearchRegionsByQuery = memoize(searchRegionsByQuery);

export { searchRegionsByQuery, memoizedSearchRegionsByQuery };
