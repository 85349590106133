import userLocalStorageApi from '@ngservices_front/api/userLocalStorageApi';
import store from '@/store';
import paymentService from '@/services/payment/paymentService';
import productService from '@/services/product/productService';
import { getProductPriceObject } from '@/utils/productUtils';
import orderApi from '@/api/orderApi';
import i18n from '@/i18n';

const COUPON_ERROR_MESSAGE_IDS = {
  err_not_found: 'order.promoCodeDoesNotExist',
  err_expired_date: 'order.promocodeHasExpired',
  err_expired_count: 'order.promocodeHasExpired',
  err_max_price: 'order.itemLimitExceeded',
  unknown_error: 'app.somethingWentWrong',
};

const setInitialData = () => {
  const { email: orderEmail } = store.state.order;
  const { price } = store.state.product;
  store.commit('order/SET_TOTAL_PRICE', price);
  if (!orderEmail) {
    const userEmail = userLocalStorageApi.getEmail();
    if (userEmail) store.commit('order/SET_ORDER_EMAIL', userEmail);
  }
};

const registerEntityCard = (cardFile) => orderApi.postEntityCard(cardFile);

const buyDataProduct = async ({ product, email, comment, customerType, legalEntityCardId, coupon }) => {
  store.commit('order/SET_ORDER_EMAIL', email);
  userLocalStorageApi.saveEmail(email);

  const orderData = {
    product,
    paymentSystemSuccessUrl: paymentService.getPaymentSuccessUrl(),
    paymentSystemFailUrl: paymentService.getPaymentFailUrl(),
    email,
    customerType,
    legalEntityCardId,
    comment,
    coupon,
  };
  // "yoomoney", "stripe", "free".
  const { payment_strategy, order_id, price_origin, price_new, payment } = await orderApi.createOrder(orderData);
  const { originPrice, priceWithDiscount } = getProductPriceObject({ lang: i18n.locale, price_origin, price_new });

  if (originPrice) store.commit('order/SET_TOTAL_PRICE', originPrice);
  if (priceWithDiscount) store.commit('order/SET_TOTAL_PRICE_WITH_DISCOUNT', priceWithDiscount);

  if (customerType === 'physical' && (payment_strategy === 'yoomoney' || payment_strategy === 'stripe')) {
    const paymentGatewayId = payment_strategy;
    paymentService.payForOrder({
      paymentGatewayId,
      orderData: { order_id, price: price_new || price_origin, payment },
    });
  }
  return { payment_strategy, order_id, originPrice, priceWithDiscount, payment };
};

const applyCoupon = async ({ product, coupon }) => {
  const { originPrice, priceWithDiscount, discount, couponStatus } = await productService.getProductPrice({
    ...product,
    coupon,
  });
  if (originPrice) store.commit('order/SET_TOTAL_PRICE', originPrice);
  if (priceWithDiscount) store.commit('order/SET_TOTAL_PRICE_WITH_DISCOUNT', priceWithDiscount);
  if (discount) store.commit('order/SET_DISCOUNT', discount);
  return {
    originPrice,
    priceWithDiscount,
    discount,
    couponStatus,
  };
};

const resetCoupon = () => {
  store.commit('order/SET_TOTAL_PRICE_WITH_DISCOUNT', undefined);
  store.commit('order/SET_DISCOUNT', undefined);
};

const resetTotalPrice = () => {
  store.dispatch('order/resetPrice', undefined);
};

export default {
  // getTotalPrice,
  setInitialData,
  registerEntityCard,
  buyDataProduct,
  applyCoupon,
  resetCoupon,
  resetTotalPrice,
};
export { COUPON_ERROR_MESSAGE_IDS };
