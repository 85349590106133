import i18n from '@/i18n';
import BaseProductService from '@/services/product/BaseProductService';
import { createSummaryStr, createSummaryStrDetailed, getProductImages } from '@/utils/productUtils/';
import { getFormatName, getFullFormatList } from '@/utils/formatUtils';
import { COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const DEM_IMAGES = [{ name: 'dem', format: 'png' }];

const getSettings = () =>
  Object.freeze({
    id: 'dem',
    description: i18n.t('product.dem.description'),
    lastUpdated: '01.04.2023',
    productionTime: 10, // in minutes
    sources: [
      {
        text: 'Copernicus DEM',
        link: 'https://spacedata.copernicus.eu/collections/copernicus-digital-elevation-model',
      },
    ],
    tags: i18n.t('product.dem.tags'),
    samplesUrl: `/${i18n.locale}/about/#formats-dem`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/1BcuWXMnwpwv0HIao3wAumgpqGsgfAzLc5yaOOCTeYBc/pubhtml',
      en: 'https://docs.google.com/spreadsheets/d/1PRSpeLWrFttCS7tz8rk--R7aoV_B8LuGbDuUbhLyWJg/pubhtml',
      es: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vS3Oq01OMLnSVywAzRZ5sXAmc-sKW9GwY8WPU9WfwZlxNMr4qgWrXGR-dFjZBNRj18uCk9vOtI3lYkS/pubhtml',
      fr: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRd0GjSzYWT3WwGaNpAKKt4_HaCObcmYtGIyVkYiMolb31MsDMaoFGHNbr-CZucbUn-11eJ3yvRNDTg/pubhtml',
    },
    demoMapUrl: {
      en: 'https://demo.nextgis.com/resource/4119/display?panel=info',
      ru: 'https://demo.nextgis.ru/resource/4108/display?panel=info',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/dem_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format_vector: {
          name: 'format_vector',
          label: `${i18n.t('product.format_vector')}:`,
          widget: 'select',
          options: getFullFormatList(COMMON_VECTOR_FORMATS),
          serverErrors: '',
          errors: [],
        },
        format_raster: {
          name: 'format_raster',
          label: `${i18n.t('product.format_raster')}:`,
          widget: 'select',
          options: getFullFormatList(['geotiff']),
          serverErrors: '',
          errors: [],
        },
        contourlines_step: {
          name: 'contourlines_step',
          label: `${i18n.t('product.contourlines_step')}:`,
          widget: 'select',
          options: [], // get by API for certain region
          serverErrors: '',
          errors: [],
          hintOutside: {
            text: `${i18n.t('product.dem.contourlinesStepHint', {
              locale: i18n.locale,
            })}`,
          },
        },
      },
    },
    productModel: {
      formModel: {
        format_vector: DEFAULT_VECTOR_FORMAT,
        format_raster: 'geotiff',
        contourlines_step: '', // get by API for certain region
      },
    },
    images: getProductImages(DEM_IMAGES, 'dem'),
  });

const isDemWithVectorLayers = (layers) => layers.includes('contours');
const isDemWithRasterLayers = (layers) => layers.includes('dem') || layers.includes('hillshade');

class DemProductService extends BaseProductService {
  getSettings() {
    return getSettings();
  }

  getSummary({ id, options }) {
    const attrs = [];
    if (isDemWithVectorLayers(options.layers)) {
      attrs.push(getFormatName(options.format_vector));
    }
    if (isDemWithRasterLayers(options.layers)) {
      attrs.push(getFormatName(options.format_raster));
    }
    return createSummaryStr({
      id,
      attrs,
      layerNumber: options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { id, options }) {
    const attrs = [];
    if (isDemWithVectorLayers(options.layers)) {
      attrs.push([i18n.t('product.format_vector'), getFormatName(options.format_vector)]);
      attrs.push([i18n.t('product.contourlines_step'), options.contourlines_step]);
    }
    if (isDemWithRasterLayers(options.layers)) {
      attrs.unshift([i18n.t('product.format_raster'), getFormatName(options.format_raster)]);
    }
    return createSummaryStrDetailed({
      regionName,
      id,
      attrs,
      layerNumber: options.layers.length,
    });
  }

  getItemCode({ id, region, options }) {
    const formats = [];
    if (isDemWithVectorLayers(options.layers)) {
      formats.push(options.format_vector);
    }
    if (isDemWithRasterLayers(options.layers)) {
      formats.push(options.format_raster);
    }
    return `${region.code}_${formats.join('_')}_${id}`;
  }
}

export { DemProductService, getSettings, DEM_IMAGES };
