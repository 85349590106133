import i18n from '@/i18n';

const CHATRA_DEFAULT_SETTINGS = {
  colors: {
    buttonText: '#0070c5',
    buttonBg: '#f8fafd',
  },
};

const init = () => {
  window.ChatraID = 'TqbMGvKSEXA4StnLB';
  window.ChatraGroupID = 'QfGRJWzSKN5EM5hgu';
  window.ChatraSetup = {
    ...CHATRA_DEFAULT_SETTINGS,
    language: i18n.locale,
  };

  const c = 'Chatra';
  const s = document.createElement('script');
  window[c] =
    window[c] ||
    // eslint-disable-next-line func-names
    function () {
      // eslint-disable-next-line prefer-rest-params
      (window[c].q = window[c].q || []).push(arguments);
    };
  s.async = true;
  s.src = `${document.location.protocol === 'https:' ? 'https:' : 'http:'}//call.chatra.io/chatra.js`;
  if (document.head) document.head.appendChild(s);
};

const updateSettings = (settings) => {
  window.ChatraSetup = settings;
  window.Chatra('restart');
};

export default {
  init,
  updateSettings,
};
