import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { createSummaryStr, createSummaryStrDetailed, getProductImages } from '@/utils/productUtils/';
import { getFormatName, getFullFormatList } from '@/utils/formatUtils';

const SAT_IMAGES = [{ name: 'satZ13', format: 'png' }];

const getSettings = () =>
  Object.freeze({
    id: 'sat',
    description: i18n.t('product.sat.description'),
    productionTime: 120, // in minutes
    sources: [
      {
        text: 'Sentinel',
        link: 'https://dataspace.copernicus.eu/explore-data/data-collections/sentinel-data/sentinel-2',
      },
    ],
    tags: i18n.t('product.sat.tags'),
    samplesUrl: `/${i18n.locale}/about/#formats-sat`,
    demoMapUrl: {
      en: 'https://demo.nextgis.com/resource/5852/display?panel=info',
      ru: 'https://demo.nextgis.ru/resource/5848/display?panel=info',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/sat_demo_map_bg.jpg`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(['geotiff']),
          serverErrors: '',
          errors: [],
        },
        zoom_level: {
          name: 'zoom_level',
          label: `${i18n.t('product.zoom_level.label')}:`,
          widget: 'select',
          options: [
            // { value: 13, text: i18n.t('product.zoom_level.value_z13') },
            { value: 14, text: i18n.t('product.zoom_level.value_z14') },
          ],
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: 'geotiff',
        zoom_level: 14,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(SAT_IMAGES, 'sat'),
  });

class SatProductService extends BaseProductService {
  getSettings() {
    return getSettings();
  }

  getSummary({ id, options }) {
    return createSummaryStr({
      id,
      attrs: [getFormatName(options.format), `z${options.zoom_level}`],
      layerNumber: options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { id, options }) {
    return createSummaryStrDetailed({
      regionName,
      id,
      attrs: [
        [i18n.t('product.format'), getFormatName(options.format)],
        [i18n.t('product.zoom_level.label'), i18n.t(`product.zoom_level.value_z${options.zoom_level}`)],
      ],
      layerNumber: options.layers.length,
    });
  }
}

export { SatProductService, getSettings, SAT_IMAGES };
