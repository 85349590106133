import BaseProductService from '@/services/product/BaseProductService';
import i18n from '@/i18n';
import { getFullFormatList } from '@/utils/formatUtils';
import { getProductImages, getProductionTimeForCachedProducts } from '@/utils/productUtils';
import { COMMON_VECTOR_FORMATS, DEFAULT_VECTOR_FORMAT } from '@/constants/product/productConstants';

const OOPT_IMAGES = [
  { name: 'oopt', format: 'png' },
  { name: 'ooptAttrs', format: 'png' },
];

const getSettings = (regionCode) =>
  Object.freeze({
    id: 'oopt',
    description: i18n.t('product.oopt.description'),
    lastUpdated: '14.12.2023',
    productionTime: getProductionTimeForCachedProducts({ lang: i18n.locale, regionCode }), // in minutes
    sources: [
      {
        text: i18n.t('product.oopt.ooptRossii'),
      },
    ],
    tags: i18n.t('product.oopt.tags'),
    samplesUrl: `/ru/about/#formats-oopt`,
    dataStructureUrl: {
      ru: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ11744S-kcUBR9gTOGv8ylhTVn2iEQzGA6m9dM0cEaKNEc0-CCm7fZTUPeftUeoms1cCf4uc7e3pjF/pubhtml',
      en: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ11744S-kcUBR9gTOGv8ylhTVn2iEQzGA6m9dM0cEaKNEc0-CCm7fZTUPeftUeoms1cCf4uc7e3pjF/pubhtml',
    },
    demoMapUrl: {
      ru: 'https://demo.nextgis.ru/resource/6144/display?panel=info',
      en: 'https://demo.nextgis.com/resource/6744/display?panel=info',
    },
    demoMapImage: `${process.env.VUE_APP_PUBLIC_PATH}img/product/oopt_demo_map_bg.png`,
    productOptions: {
      formFields: {
        format: {
          name: 'format',
          label: `${i18n.t('product.format')}:`,
          widget: 'select',
          options: getFullFormatList(COMMON_VECTOR_FORMATS),
          serverErrors: '',
          errors: [],
        },
      },
    },
    productModel: {
      formModel: {
        format: DEFAULT_VECTOR_FORMAT,
      },
    },
    layerList: {
      attrs: {
        disabled: true,
      },
    },
    images: getProductImages(OOPT_IMAGES, 'oopt'),
  });

class OoptProductService extends BaseProductService {
  getSettings(regionCode) {
    return getSettings(regionCode);
  }
}

export { OoptProductService, getSettings, OOPT_IMAGES };
