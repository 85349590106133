import i18n from '@/i18n';
import { createSummaryStr, createSummaryStrDetailed } from '@/utils/productUtils/';
import { getFormatName } from '@/utils/formatUtils';

export default class BaseProductService {
  getSummary({ id, options }) {
    return createSummaryStr({
      id,
      attrs: [getFormatName(options.format)],
      layerNumber: options.layers.length,
    });
  }

  getSummaryDetailed(regionName, { id, options }) {
    return createSummaryStrDetailed({
      regionName,
      id,
      attrs: [[i18n.t('product.format'), getFormatName(options.format)]],
      layerNumber: options.layers.length,
    });
  }

  getItemCode({ id, region, options }) {
    return `${region.code}_${options.format}_${id}`;
  }

  isOptionsChangedSignificantly() {
    return true;
  }
}
