import httpClient from './httpClient';

const createOrder = (orderData) =>
  httpClient.post('/api/product_order/', orderData).then((resp) => resp.data);

const postEntityCard = (cardFile) => {
  const formData = new FormData();
  formData.append('card', cardFile);
  return httpClient
    .post('/api/entitycards/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((resp) => resp.data);
};

export default { createOrder, postEntityCard };
