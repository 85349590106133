<template>
  <form method="post" action="https://yoomoney.ru/eshop.xml">
    <input name="shopId" value="96520" type="hidden" />
    <input name="scid" value="92170" type="hidden" />
    <input name="paymentType" value="" type="hidden" />
    <input name="shopDefaultUrl" :value="defaultUrl" type="hidden" />
    <input name="shopSuccessURL" :value="successUrl" type="hidden" />
    <input name="shopFailURL" :value="failUrl" type="hidden" />
    <input name="sum" :value="finalTotalPrice.amount" type="hidden" />
    <input name="customerNumber" :value="orderId" type="hidden" />
    <input name="cps_email" :value="email" type="hidden" />
    <input name="ym_merchant_receipt" :value="ymMerchantReceipt" type="hidden" />
    <input name="ng_service_name" value="data_order" type="hidden" />
    <input name="ng_item_code" :value="itemCode" type="hidden" />
    <input name="ng_customer_email" :value="email" type="hidden" />
  </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import productService from '@/services/product/productService';
import paymentService from '@/services/payment/paymentService';
import { YOOMONEY_PAYMENT_METHOD_TYPE, YOOMONEY_PAYMENT_SUBJECT_TYPE } from '@/constants/payment/paymentConstants';

export default {
  name: 'YoomoneyForm',
  data() {
    return {
      defaultUrl: window.location.href,
      successUrl: paymentService.getPaymentSuccessUrl(),
      failUrl: paymentService.getPaymentFailUrl(),
      orderId: undefined,
    };
  },
  computed: {
    ...mapState('order', ['email']),
    ...mapGetters('order', ['finalTotalPrice']),
    ...mapGetters('product', ['productDataFormatted']),
    itemCode() {
      return productService.getItemCode(this.productDataFormatted);
    },
    ymMerchantReceipt() {
      const productName = productService.getSummaryNoHTML(this.productDataFormatted);
      return JSON.stringify({
        customerContact: this.email,
        items: [
          {
            quantity: 1,
            price: {
              amount: this.finalTotalPrice.amount,
            },
            tax: 1,
            text: `Сервис геоданных NextGIS, ${productName}, ${this.productDataFormatted.region.code}`,
            paymentMethodType: YOOMONEY_PAYMENT_METHOD_TYPE,
            paymentSubjectType: YOOMONEY_PAYMENT_SUBJECT_TYPE,
          },
        ],
      });
    },
  },
};
</script>
