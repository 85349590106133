const FORMATS = {
  shape: { value: 'shape', text: 'Shape (QGIS)' },
  'shape-arcgis': { value: 'shape-arcgis', text: 'Shape (ArcGIS)' },
  gpkg: { value: 'gpkg', text: 'GeoPackage (QGIS)' },
  'geodatabase-arcgis': { value: 'geodatabase-arcgis', text: 'Geodatabase (ArcGIS)' },
  geojson: { value: 'geojson', text: 'GeoJSON (QGIS)' },
  mapinfo: { value: 'mapinfo', text: 'TAB (Mapinfo)' },
  mif: { value: 'mif', text: 'MIF/MID (Mapinfo)' },
  kml: { value: 'kml', text: 'KML (Google Earth)' },
  postgresql: { value: 'postgresql', text: 'SQL (PostgreSQL/PostGIS)' },
  'pbf-osm': { value: 'pbf-osm', text: 'PBF (OSM)' },
  'xml-osm': { value: 'xml-osm', text: 'XML (OSM)' },
  pdf: { value: 'pdf', text: 'PDF' },
  csv: { value: 'csv', text: 'CSV (Excel)' },
  geotiff: { value: 'geotiff', text: 'GeoTIFF' },
};

const getFormatName = (key) => FORMATS[key] && FORMATS[key].text;

const getFullFormatList = (keys) => keys.map((formatKey) => FORMATS[formatKey]);

export { FORMATS, getFormatName, getFullFormatList };
