const PAYMENT_SUCCESS_QUERY = 'payment-success';
const PAYMENT_FAIL_QUERY = 'payment-fail';
const YOOMONEY_PAYMENT_METHOD_TYPE = 'full_payment';
const YOOMONEY_PAYMENT_SUBJECT_TYPE = 'service';

export {
  PAYMENT_SUCCESS_QUERY,
  PAYMENT_FAIL_QUERY,
  YOOMONEY_PAYMENT_METHOD_TYPE,
  YOOMONEY_PAYMENT_SUBJECT_TYPE,
};
