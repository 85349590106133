<template>
  <component :is="layoutComponent" v-bind="layoutComponentProps">
    <router-view></router-view>
  </component>
</template>

<script>
import config from '@/config';
import userService from '@/services/userService';

export default {
  data() {
    return {};
  },
  computed: {
    layoutComponent() {
      return (
        (this.$route.meta.layout && this.$route.meta.layout.component) || {
          render() {
            return this.$slots.default;
          },
        }
      );
    },
    layoutComponentProps() {
      return this.$route.meta.layout && this.$route.meta.layout.props;
    },
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.$store.dispatch('baseApp/init', config());
      },
      immediate: true,
    },
  },
  async beforeCreate() {
    try {
      const { from_country } = await userService.getUserVisitInfo();
      this.$store.commit('baseUser/SET_USER_IP_COUNTRY', from_country);
    } catch (e) {
      this.$store.commit('baseUser/SET_USER_IP_COUNTRY', null);
      console.error(e);
    }
  },
};
</script>

<style lang="scss" scoped></style>
